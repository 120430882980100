import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { FluidImage } from "src/Types"

import { Label1, Paragraph3 } from "src/components/Text"
import Colors from "src/styles/Colors"
import media from "src/styles/media"

type Props = {
  data: {
    sneaker: FluidImage
    astro1: FluidImage
    lineGrid: FluidImage
    tokens: FluidImage
  }
}

const Features: React.FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      <Feature>
        <Label>nft drops</Label>
        <FeatureDescription>
          sell unique, ownable digital goods
        </FeatureDescription>
      </Feature>
      <Feature>
        <Label>gaming</Label>
        <FeatureDescription>
          Ready to start your open economy?
        </FeatureDescription>
      </Feature>
      <Feature>
        <Label>community tokens</Label>
        <FeatureDescription>
          Want to sell unique goods to your people?
        </FeatureDescription>
      </Feature>
      <Sneaker>
        <Img fluid={data.sneaker.childImageSharp.fluid} />
      </Sneaker>
      <Grid>
        <Img fluid={data.lineGrid.childImageSharp.fluid} />
      </Grid>
      <Astro>
        <Img fluid={data.astro1.childImageSharp.fluid} />
      </Astro>
      <Tokens>
        <Img fluid={data.tokens.childImageSharp.fluid} />
      </Tokens>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 72.2vw;
  height: 21.3vw;
  position: absolute;
  top: 68vw;
  left: 13.89%;
  border: solid 1px ${Colors.yellow};
  background: ${Colors.charcoal};
  display: flex;
  justify-content: space-between;
  padding: 11.9vw 3.5vw 0;
  ${media.tablet} {
    border: none;
    top: 282.8vw;
    height: min-content;
    display: block;
    width: 100%;
    left: 4.8vw;
    padding: 22.2vw 0 0;
  }
`
const Feature = styled.div`
  width: 15.4vw;
  ${media.tablet} {
    border: solid 1px ${Colors.yellow};
    &:nth-child(1) {
      width: 79.9vw;
      height: 66.4vw;
      margin-bottom: 24.4vw;
      padding: 39.9vw 0 0 26.33vw;
      margin-left: 10.4vw;
    }
    &:nth-child(2) {
      margin-bottom: 31.4vw;
      padding: 34.8vw 15.5vw 0 7.2vw;
      width: 75.4vw;
      height: 62.8vw;
    }
    &:nth-child(3) {
      padding: 31.4vw 10.4vw 0 10.6vw;
      width: 74.6vw;
      height: 60.6vw;
      margin-left: 15.7vw;
    }
  }
`
const Label = styled(Label1)`
  color: ${Colors.yellow};
  font-size: 1.7vw;
  letter-spacing: -0.04em;
  ${media.tablet} {
    font-size: 6vw;
  }f
`
const FeatureDescription = styled(Paragraph3)`
  color: white;
`
const Sneaker = styled.div`
  position: absolute;
  width: 21.7vw;
  top: -5.1vw;
  left: -2.3vw;
  ${media.tablet} {
    width: 75.3vw;
    left: -3.1vw;
    top: 0;
  }
`
const Grid = styled.div`
  width: 14.7vw;
  position: absolute;
  top: 1.1vw;
  left: 29.6vw;
  filter: drop-shadow(3px 3px 0px #000000);
  ${media.tablet} {
    width: 51.2vw;
    top: 110vw;
  }
`
const Astro = styled.div`
  position: absolute;
  width: 29.6vw;
  top: -5vw;
  left: 15.7vw;
  ${media.tablet} {
    width: 90.1vw;
    left: -13.8vw;
    top: 93vw;
  }
`
const Tokens = styled.div`
  position: absolute;
  width: 13.6vw;
  top: -2.7vw;
  left: 51.9vw;
  ${media.tablet} {
    width: 47.3vw;
    top: 183vw;
    left: 3.4vw;
  }
`

export default Features
